import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { prop } from '../../utilities';
import APIClass, { ThenArg } from '../../services/API';
import { startFetching, stopFetching } from '../../utilities/redux';
import { AppThunk } from '../../rootReducer';
import { strictEqual } from 'assert';
import { getImagePath } from '../../utilities/product';
import { hostnameSelector, langSelector } from '../App/selectors';
import {
  BreadCrumbType,
  setBreadCrumbPath,
} from '../BreadCrumb/breadCrumbSlice';
import { loadCategoryProducts } from '../Category/actions';
import { resolveDomainByHostname } from '../../configureTrans';

export interface SingleActionItemInterface {
  endedDate: Date | null;
  title: string;
  description: string;
  annotation: string;
  imageUrl: string | null;
  products: any[];
  dateToReadable: string;
  dateFromReadable: string;
  totalProducts: number;
}

interface ActionsState {
  action: {
    data: SingleActionItemInterface | null;
    isFetching: boolean;
  };
}

const initialState: ActionsState = {
  action: {
    data: null,
    isFetching: false,
  },
};

const actionSlice = createSlice({
  name: 'action',
  initialState,
  reducers: {
    startFethingAction(state) {
      startFetching(state, 'action');
    },

    setAction(
      state,
      action: PayloadAction<{
        action: SingleActionItemInterface;
      }>,
    ) {
      stopFetching(state, 'action');
      state.action.data = action.payload.action;
    },

    stopFetchingAction(state) {
      stopFetching(state, 'action');
    },
  },
});

export const {
  startFethingAction,
  setAction,
  stopFetchingAction,
} = actionSlice.actions;

export default actionSlice.reducer;

export const fetchAction = (actionId, filters): AppThunk => async (
  dispatch,
  getState,
  API,
) => {
  try {
    dispatch(startFethingAction());
    const lang = langSelector(getState());
    const hostname = hostnameSelector(getState());

    const actions: /*ThenArg<typeof APIClass.loadLandingPages>*/ any = await API.loadLandingPages(
      {
        withContent: 1,
        id: actionId,
        lang,
        productLimit: 12,
        productOffset: 0,
        limit: 100,
        domainId: [+resolveDomainByHostname(hostname)],
      },
    );

    const action =
      actions.web_landing_pages && actions.web_landing_pages.length
        ? actions.web_landing_pages[0]
        : null;
    if (action) {
      dispatch(
        setAction({
          action: {
            endedDate: action.dateTo ? new Date(action.dateTo) : null,
            title: action.name ? action.name : '',
            dateFromReadable: action.dateFromReadable,
            dateToReadable: action.dateToReadable,
            description: action.content ? action.content.json_content.body : '',
            annotation: action.content
              ? action.content.json_content.annotation
              : '',
            imageUrl:
              action.content &&
              action.content.photogallery &&
              action.content.photogallery.length
                ? getImagePath(action.content.photogallery[0].document_path, {
                    width: 1024,
                    height: 768,
                  })
                : null,
            products: action.content.products,
            totalProducts: actions.products_pagination.total,
          },
        }),
      );

      if (action.id === 26) {
        await dispatch(
          loadCategoryProducts(
            -1,
            {
              ...filters,
              sale: 1,
              onlyWithoutParent: action.includeRecycled ? undefined : '1',
            },
            false,
            false,
          ),
        );
      } else {
        await dispatch(
          loadCategoryProducts(
            -1,
            {
              ...filters,
              webContentIds: [action.descrContentId],
              onlyWithoutParent: action.includeRecycled ? undefined : '1',
            },
            false,
            false,
          ),
        );
      }

      dispatch(setBreadCrumbPath(BreadCrumbType.ACTION, { name: action.name }));
    }
  } catch (e) {
    dispatch(stopFetchingAction());
  }
};

const actionDomainSelector = state => state.action;

export const actionSelector = createSelector(actionDomainSelector, substate =>
  prop(substate, 'action.data', null),
);
