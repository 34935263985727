import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import {
  FlexRowCenterVertical,
  DsiHeaderLogo as HeaderSmallLogo,
  FlexRow,
  FlexColCenter,
  FlexCol,
  DsiSearchForm as SearchForm,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import HeaderCart from './HeaderCart';
import HeaderProfile from './HeaderProfile';
import { Navigation } from './Navigation';
import { DesktopWrapper } from '../_helpers/Default/ResponsiveWrappers';
// import { HeaderFavourite } from './HeaderFavourite';
import { HeaderLangButton } from './HeaderLangButton';
import { __ } from 'react-i18n';
import * as cookies from 'react-cookies';

interface Props {
  lang: string;
  items: ThenArg<typeof API.loadTree>;
  landingPages: any;
  dispatch: (action: any) => void;
  socialUrls: {
    fbUrl: string;
    igUrl: string;
    ytUrl: string;
  };
  contact: {
    email: string;
    phone: string;
  };
  toggleResponsiveCategoryMenu: any;
  handleSearchFocus: any;
  hideSearchResultsViewer: () => void;
  resultsDropdownVisible: boolean;
  searchTerm: string;
  handleSearchTermChange: (e: any) => void;
  clearSearchInput: () => void;
  redirectToSearchResultsPage: (e: any) => void;
  changeDimmerVisible: (newVal: boolean) => void;
  partialSearchProducts: ThenArg<typeof API.searchProducts>;
  partialSearchProductsIsFetching: boolean;
  partialSearchCategories: ThenArg<typeof API.loadCategories>;
  partialSearchCategoriesIsFetching: boolean;
  user: any;
  isHome: boolean;
  headerArticles: any;
  partialSearchArticles: any;
  partialSearchArticlesIsFetching: boolean;
  byId: any;
  logoSrc: string;
  cartPrice: number;
  cartCurrency: string;
  cartData: ThenArg<typeof API.getCart>;
  domainId: string;
}

/*****************/

const StyledDesktopWrapper = styled(DesktopWrapper)`
  margin: 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: column;
`;

const HeaderWrapper = styled(FlexCol)`
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.textPrimary};
  margin: 0 auto;
  width: 100%;
  box-shadow: ${({ theme }) => theme.boxShadow.default};
  align-items: center;

  @media print {
    display: none;
  }

  ${({ theme }) => theme.mediab.l925`
      margin: 0;
  `}
`;

const UpperHeader = styled.div`
  border-bottom: ${({ theme }) => theme.borders.primary};
  width: 100%;
`;

const UpperHeaderWrapper = styled(FlexRow)`
  padding: ${rem(12)};
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: ${({ theme }) => `${theme.grid.container.wide}px`};

  margin: 0 auto;
`;

const BottomHeader = styled(FlexRow)`
  padding: 0 ${rem(12)};
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  max-width: ${({ theme }) => `${theme.grid.container.wide}px`};
`;

const MiddleWrapper = styled(FlexColCenter)`
  width: 100%;
  max-width: ${rem(585)};
  margin: 0 ${rem(16)};

  ${({ theme }) => theme.mediab.l1300`
    max-width: ${rem(500)};
  `}
  ${({ theme }) => theme.mediab.l1050`
    max-width: ${rem(300)};
  `}
`;

const RightWrapper = styled(FlexRowCenterVertical)`
  width: 100%;
  max-width: ${rem(450)};
  justify-content: flex-end;

  ${({ theme }) => theme.mediab.l1050`
    max-width: ${rem(200)};
    margin-right: ${rem(30)};
  `}
`;

function DesktopHeader({
  user,
  contact: { phone, email },
  handleSearchFocus,
  hideSearchResultsViewer,
  resultsDropdownVisible,
  searchTerm,
  handleSearchTermChange,
  clearSearchInput,
  redirectToSearchResultsPage,
  changeDimmerVisible,
  items,
  landingPages,
  partialSearchProducts,
  partialSearchProductsIsFetching,
  partialSearchCategories,
  partialSearchCategoriesIsFetching,
  dispatch,
  isHome,
  headerArticles,
  lang,
  partialSearchArticles,
  partialSearchArticlesIsFetching,
  byId,
  logoSrc,
  cartPrice,
  cartCurrency,
  cartData,
  domainId,
}: Props) {
  if (partialSearchProducts) {
    partialSearchProducts.products = partialSearchProducts.products.map(
      (psr: any) => {
        const mainGoodCandidates = psr.product_detail.goods.filter(
          g => g.on_stock_count > 0 && g.availability.order_available,
        );
        const mainGood = mainGoodCandidates.length
          ? mainGoodCandidates[0]
          : psr.product_detail.goods[0];

        if (mainGood) {
          mainGood.unitprice = mainGood.final_price;
          psr.product_detail.main_good = mainGood;
        }

        return psr;
      },
    );

    if (
      partialSearchProducts.products &&
      partialSearchProducts.products.length
    ) {
      partialSearchProducts.products = [partialSearchProducts.products[0]];
    }
  }

  return (
    <HeaderWrapper>
      <StyledDesktopWrapper>
        <UpperHeader>
          <UpperHeaderWrapper>
            <HeaderSmallLogo
              isSmall={logoSrc.includes('jbl')}
              logo={logoSrc}
              alt={'Logo webu'}
            />
            <MiddleWrapper>
              <SearchForm
                showWithVat={true}
                showMoc={true}
                partialSearchArticlesIsFetching={
                  partialSearchArticlesIsFetching
                }
                searchResults={{
                  productsResult: partialSearchProducts,
                  categoriesResult: partialSearchCategories,
                  articlesResult: partialSearchArticles,
                }}
                redirectToSearchResultPage={redirectToSearchResultsPage}
                searchTerm={searchTerm}
                handleSearchTermChange={handleSearchTermChange}
                clearSearchInput={clearSearchInput}
                handleFocus={handleSearchFocus}
                hideResultViewer={hideSearchResultsViewer}
                resultsDropdownVisible={resultsDropdownVisible}
                partialSearchProductsIsFetching={
                  partialSearchProductsIsFetching
                }
                partialSearchCategoriesIsFetching={
                  partialSearchCategoriesIsFetching
                }
                buttonHasArrow={true}
              />
            </MiddleWrapper>
            <RightWrapper>
              {/* {cookies.load('IrhjUTUTnB') === 'RAD6YwnIpj' ? (
                <HeaderProfile user={user} dispatch={dispatch} />
              ) : (
                <></>
              )}
              */}
              <HeaderProfile user={user} dispatch={dispatch} />
              {/* <HeaderFavourite /> */}
              <HeaderCart
                numberOfProducts={2}
                totalPrice={cartPrice}
                currency={cartCurrency}
                cartWidth={300}
                cartData={cartData}
                dispatch={dispatch}
              />
              <HeaderLangButton dispatch={dispatch} lang={lang} />
            </RightWrapper>
          </UpperHeaderWrapper>
        </UpperHeader>
        <BottomHeader>
          <Navigation
            user={user}
            changeDimmerVisible={changeDimmerVisible}
            items={items}
            landingPages={landingPages}
            isHome={isHome}
            navArticles={headerArticles}
            dispatch={dispatch}
            lang={lang}
            byId={byId}
          />
        </BottomHeader>
      </StyledDesktopWrapper>
    </HeaderWrapper>
  );
}

export default DesktopHeader;
