import { createSelector } from 'reselect';
import { prop } from '../../utilities';

export const headerDomainSelector = state => state.header || {};

export const categoriesDomainSelector = createSelector(
  headerDomainSelector,
  substate => prop(substate, 'categories', {}),
);

export const categoryEntitiesSelector = createSelector(
  categoriesDomainSelector,
  substate => prop(substate, 'byId', null),
);

export const categoriesIsFetchingSelector = createSelector(
  categoriesDomainSelector,
  substate => prop(substate, 'isFetching', true),
);

export const mainCategoriesIdSelector = createSelector(
  categoriesDomainSelector,
  substate => prop(substate, 'mainCategories', []),
);

export const searchIsFetchingSelector = createSelector(
  headerDomainSelector,
  substate => prop(substate, 'searchForm.isFetching', false),
);

export const searchResultsSelector = createSelector(
  headerDomainSelector,
  substate => prop(substate, 'searchForm.results', null),
);

export const partialSearchResultsSelector = createSelector(
  headerDomainSelector,
  substate => prop(substate, 'searchForm.partialArticles', null),
);

export const productCategoriesSelector = createSelector(
  categoriesDomainSelector,
  substate => prop(substate, 'productCategories', null),
);

export const productCategoriesIsFetchingSelector = createSelector(
  categoriesDomainSelector,
  substate => prop(substate, 'isFetching', null),
);

export const homeDimmerSelector = createSelector(
  categoriesDomainSelector,
  substate => prop(substate, 'dimmerVisible', false),
);

export const landingPagesSelector = createSelector(
  headerDomainSelector,
  substate => prop(substate, 'landingPages.landingPages', []),
);
