import { rem } from 'polished';

export const InputSlider = `
.input-range__slider {
    appearance: none;
    background: #26B25E;
    border-radius: 100%;
    cursor: pointer;
    display: block;
    height: 1rem;
    margin-left: -0.5rem;
    margin-top: -0.65rem;
    outline: none;
    position: absolute;
    top: 50%;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
    width: 1rem;
  }
  .input-range__slider:active {
    transform: scale(1.3);
  }
  .input-range__slider:focus {
    box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
  }
  .input-range--disabled .input-range__slider {
    background: #cccccc;
    border: 1px solid #cccccc;
    box-shadow: none;
    transform: none;
  }
  
  .input-range__slider-container {
    transition: left 0.3s ease-out;
  }
  
  .input-range__label {
    display: none;
    color: #aaaaaa;
    font-family: "Helvetica Neue", san-serif;
    font-size: 0.8rem;
    transform: translateZ(0);
    white-space: nowrap;
  }
  
  .input-range__label--min,
  .input-range__label--max {
    // bottom: -1.4rem;
    // position: absolute;
    display: none;
  }
  
  .input-range__label--min {
    display: none;
    left: 0;
  }
  
  .input-range__label--max {
    right: 0;
  }
  
  .input-range__label--value {
    position: absolute;
    top: -1.8rem;
  }
  
  .input-range__label-container {
    left: -50%;
    position: relative;
  }
  .input-range__label--max .input-range__label-container {
    left: 50%;
  }
  
  .input-range__track {
    background: rgba(38, 178, 94, 0.25);
    border-radius: 0.3rem;
    cursor: pointer;
    display: block;
    height: ${rem(4)};
    position: relative;
    transition: left 0.3s ease-out, width 0.3s ease-out;
  }
  .input-range--disabled .input-range__track {
    background: #EAEAEA;    
  }
  
  .input-range__track--background {
    left: 0;
    position: absolute;
    right: 0;
    top: 50%;
  }
  
  .input-range__track--active {
    background: rgba(38, 178, 94, 1);
  }
  
  .input-range {
    height: 1rem;
    position: relative;
  }
  
  
`;
