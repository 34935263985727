import React, { useState } from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import {
  FlexRowCenterVertical,
  FlexRow,
  FlexCol,
  DsiNavigationItem as NavigationItem,
  ItemType,
  CorporateNavigationDropdownContent as NavigationDropdownContent,
  DsiSvgIcon as SvgIcon,
  CorporateNavigationItems as NavigationItems,
  OutsideClick,
  TextP,
  MenuState,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import { IconType } from 'eshop-defaults/lib/components/Dsi/General/SvgIcon';
import { Link } from 'react-router';
import { __, __r } from 'react-i18n/lib';
import { push } from 'react-router-redux';
import { prop } from '../../utilities';
import { resolveCategoryUrl } from 'eshop-defaults/lib/utilities/selectors';

export interface Props {
  user?: any;
  items: ThenArg<typeof API.loadSubTree>;
  landingPages: any;
  changeDimmerVisible: (newVal: boolean) => void;
  isHome: boolean;
  navArticles: any;
  dispatch: any;
  lang: string;
  byId: any;
}

function Navigation({
  items,
  landingPages,
  changeDimmerVisible,
  user,
  isHome,
  navArticles,
  dispatch,
  byId,
  lang,
}: Props) {
  const [isOpen, setIsOpen] = useState(isHome);
  const [menuState, setMenuState] = useState(MenuState.CLOSED);

  const [categoryId, setCategoryId] = React.useState(-1);

  const toggleDimmerOnResize = e => {
    const { innerWidth } = e.currentTarget;
    if (innerWidth <= 1150) {
      changeDimmerVisible(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener('resize', toggleDimmerOnResize);

    return () => {
      window.removeEventListener('resize', toggleDimmerOnResize);
    };
  }, []);

  const closeNav = () => {
    setIsOpen(false);
    changeDimmerVisible(false);
  };

  const toggleNav = () => {
    setIsOpen(!isOpen);
    changeDimmerVisible(!isOpen);
  };

  const openDropdown = (e: any, item: any) => {
    const { category_id: id, url } = item;
    e.preventDefault();
    if (id === categoryId && isOpen) {
      // setIsOpen(false);
      changeDimmerVisible(false);
    } else {
      const activeItems = items && items.find(i => i.category_id === id);

      const regex = RegExp('oblubene');
      const isFavourite = regex.test(url);

      if (
        (!activeItems ||
          !activeItems.children ||
          activeItems.children.length === 0) &&
        !isFavourite
      ) {
        // setIsOpen(false);
        changeDimmerVisible(false);
        setCategoryId(-1);
        // dispatch(
        //   push(
        //     `${__r('routes:kategoria', '/kategoria')}/${id}/${url || 'url'}`,
        //   ),
        // );
        setMenuState(MenuState.CLOSED);
      } else {
        setIsOpen(true);
        changeDimmerVisible(true);
        setCategoryId(id);

        if (isFavourite) {
          setMenuState(MenuState.FAVOURITES_SHOWN);
        } else {
          setMenuState(MenuState.CATEGORIES_SHOWN);
        }
      }
    }
  };

  const activeItems = items && items.find(i => i.category_id === categoryId);
  const dropdownChildren = (
    <SelectedItemDropdownWrapper>
      <InnerWrapper>
        <NavigationItemsWrapper>
          {activeItems && (
            <NavigationItems
              onClickDropdown={toggleNav}
              isOpenDropdown={isOpen}
              category={
                activeItems
                  ? {
                      ...activeItems,
                      image: activeItems.image,
                    }
                  : null
              }
              level={0}
              navigationType={menuState}
              byId={byId}
            />
          )}
        </NavigationItemsWrapper>
      </InnerWrapper>
    </SelectedItemDropdownWrapper>
  );

  return (
    <Wrapper>
      {/* <StyledOutsideClick handleFunction={closeNav}> */}
      <NavWrapper onMouseLeave={closeNav}>
        <RightNav>
          <TertiaryItems>
            {items && (
              <>
                {items.map((item: any, i) => (
                  <NavigationItem
                    onClick={closeNav}
                    onMouseOver={e => openDropdown(e, item)}
                    url={
                      prop(item, 'publish.0.redirect_url')
                        ? prop(item, 'publish.0.redirect_url')
                        : item.weburl
                        ? item.weburl
                        : resolveCategoryUrl(
                            item.category_id,
                            prop(
                              byId,
                              `${item.redirect_category_id}.publish.0.url`,
                            ) ||
                              prop(item, 'publish.0.url') ||
                              item.url ||
                              'url',
                            'CATEGORYTEXT/URL',
                          )
                    }
                    type={ItemType.TERTIARY}
                    title={
                      prop(item, 'publish.0.content.json_content.shortname') ||
                      prop(item, 'publish.0.category_name') ||
                      item.category_name
                    }
                    key={item.category_id}
                    withoutLeftOffset={i === 0}
                  />
                ))}

                {/* <NavigationItem
                    onClick={closeNav}
                    url={'/moj-ucet/oblubene'}
                    type={ItemType.TERTIARY}
                    title={'Obľúbené'}
                  /> */}
                {navArticles.map((navArticle, i) => {
                  if (
                    prop(navArticle, 'uniqid', '') &&
                    prop(navArticle, 'uniqid', '').includes('AKCIE')
                  ) {
                    return (
                      <NavigationItem
                        onClick={closeNav}
                        url={`/${navArticle.url}`}
                        type={ItemType.TERTIARY}
                        title={navArticle.name}
                      />
                    );
                  }

                  return null;
                })}

                {landingPages?.length ? (
                  <>
                    {landingPages.map(landingPage => {
                      return (
                        <NavigationItem
                          onClick={closeNav}
                          url={`${__r('routes:akcie', '/akcie')}/${
                            landingPage.id
                          }/${landingPage.url.replace(/^\/|\/$/g, '')}`}
                          type={ItemType.TERTIARY}
                          title={landingPage.name}
                        />
                      );
                    })}
                  </>
                ) : (
                  <></>
                )}

                {/* <NavigationItem
                  onClick={closeNav}
                  url={__r('routes:akcie', '/akcie')}
                  type={ItemType.TERTIARY}
                  title={'Akcie'}
                /> */}
              </>
            )}
          </TertiaryItems>
          <TertiaryItems>
            {navArticles.map((navArticle, i) => {
              if (
                prop(navArticle, 'uniqid', '') &&
                prop(navArticle, 'uniqid', '').includes('AKCIE')
              ) {
                return null;
              }
              return (
                <NavigationItem
                  outsideLink={navArticle.link}
                  onClick={closeNav}
                  url={`/${navArticle.url}`}
                  type={ItemType.TERTIARY}
                  title={navArticle.name}
                />
              );
            })}
            <NavigationItem
              onClick={closeNav}
              url={__r('routes:predajcovia', '/predajcovia')}
              type={ItemType.TERTIARY}
              title={__('Predajcovia')}
            />
            {/* <NavigationItem
              onClick={closeNav}
              url={__r('routes:podpora', '/podpora/faq')}
              type={ItemType.TERTIARY}
              title={'Podpora'}
            /> */}
          </TertiaryItems>
        </RightNav>
        {isOpen && (
          <NavigationDropdownContent
            fromTop={user ? 164 : 164}
            children={dropdownChildren}
          />
        )}
      </NavWrapper>
      {/* </StyledOutsideClick> */}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;

const StyledOutsideClick = styled(OutsideClick)`
  width: 100%;
  max-width: ${({ theme }) => `${theme.grid.container.wide}px`};
`;

const NavWrapper = styled(FlexRow)`
  width: 100%;
  max-width: ${({ theme }) => `${theme.grid.container.wide}px`};
  position: relative;
`;

const RightNav = styled(FlexRowCenterVertical)`
  width: 100%;
  justify-content: space-between;
`;

const TertiaryItems = styled(FlexRowCenterVertical)``;

const SelectedItemDropdownWrapper = styled(FlexRow)`
  background-color: ${({ theme }) => theme.colors.white};
  position: relative;
  width: 100%;

  ${({ theme }) => theme.mediab.l1050`
    max-width: ${rem(900)};
  `}
`;

const InnerWrapper = styled(FlexCol)`
  width: 100%;
`;

const NavigationItemsWrapper = styled.div``;

export { Navigation };
