import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { prop } from '../../utilities';
import {
  resolveCategoryUrl,
  resolveProductUrl,
} from 'eshop-defaults/lib/utilities/selectors';
import { __, __r } from 'react-i18n';
import { cartRoutes } from '../Cart/utilities';

export enum BreadCrumbType {
  CATEGORY = 'CATEGORY',
  PRODUCT = 'PRODUCT',
  LOGIN = 'LOGIN',
  REGISTRATION = 'REGISTRATION',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  MY_ACCOUNT_ORDERS = 'MY_ACCOUNT_ORDERS',
  MY_ACCOUNT_ORDER_DETAIL = 'MY_ACCOUNT_ORDER_DETAIL',
  MY_ACCOUNT_DASHBOARD = 'MY_ACCOUNT_DASHBOARD',
  MY_ACCOUNT_INVOICES = 'MY_ACCOUNT_INVOICES',
  MY_ACCOUNT_PAYABLES = 'MY_ACCOUNT_PAYABLES',
  MY_ACCOUNT_PURCHASE = 'MY_ACCOUNT_PURCHASE',
  MY_ACCOUNT_REQUESTS = 'MY_ACCOUNT_REQUESTS',
  MY_ACCOUNT_BACKORDER = 'MY_ACCOUNT_BACKORDER',
  MY_ACCOUNT_FAVORITES = 'MY_ACCOUNT_FAVORITES',
  MY_ACCOUNT_WATCHING = 'MY_ACCOUNT_WATCHING',
  MY_ACCOUNT_REQUESTS_DETAIL = 'MY_ACCOUNT_REQUESTS_DETAIL',
  MY_ACCOUNT_INFO = 'MY_ACCOUNT_INFO',
  MY_ACCOUNT_DELIVERY_ADDRESSES = 'MY_ACCOUNT_DELIVERY_ADDRESSES',
  MY_ACCOUNT_ACCOUNTS = 'MY_ACCOUNT_ACCOUNTS',
  MY_ACCOUNT_CHANGE_PASSWORD = 'MY_ACCOUNT_CHANGE_PASSWORD',
  MY_ACCOUNT_SETTINGS = 'MY_ACCOUNT_SETTINGS',
  MY_ACCOUNT_SERIAL_NUMBERS = 'MY_ACCOUNT_SERIAL_NUMBERS',
  CONTACT = 'CONTACT',
  CMS_ARTICLES = 'CMS_ARTICLES',
  CMS = 'CMS',
  NONE = 'NONE',
  CART = 'CART',
  CUSTOM = 'CUSTOM',
  ACTIONS = 'ACTIONS',
  ACTION = 'ACTION',
  SUPPORT = 'SUPPORT',
  FAQ = 'FAQ',
  SELLERS = 'SELLERS',
  SEARCH = 'SEARCH',
  BRAND = 'BRAND',
}

export type SingleBreadCrumb = {
  name: string;
  url: string;
  justText?: boolean;
};
export type BreadCrumbPath = SingleBreadCrumb[];

interface BreadCrumbState {
  path: BreadCrumbPath;
}

const initialState: BreadCrumbState = {
  path: [],
};

const breadCrumbSlice = createSlice({
  name: 'breadCrumb',
  initialState,
  reducers: {
    setBreadCrumb(
      state,
      action: PayloadAction<{
        path: BreadCrumbPath;
      }>,
    ) {
      state.path = action.payload.path;
    },
  },
});

export const { setBreadCrumb } = breadCrumbSlice.actions;

export default breadCrumbSlice.reducer;

const breadCrumbDomainSelector = state => state.breadCrumb;

export const breadCrumbSelector = createSelector(
  breadCrumbDomainSelector,
  substate => prop(substate, 'path', null),
);

export const setBreadCrumbPath = (
  type: BreadCrumbType,
  data?: any,
  byId?: any,
): any => async (dispatch, getState) => {
  const path: BreadCrumbPath = [];
  switch (type) {
    case BreadCrumbType.CATEGORY: {
      prop(data, 'parent_categories', []).map(parentCat => {
        parentCat.category_parent &&
          path.push(createBreadCrumbFromCategory(parentCat, byId));
        return 0;
      });
      path.push(createBreadCrumbFromCategory(data, byId));
      break;
    }

    case BreadCrumbType.PRODUCT: {
      prop(data, 'parent_categories', []).map(parentCat => {
        parentCat.category_parent &&
          path.push(createBreadCrumbFromCategory(parentCat, byId));
      });
      path.push(createBreadCrumbFromProduct(data));
      break;
    }

    case BreadCrumbType.LOGIN: {
      path.push({
        name: __('Prihlásenie B2C'),
        url: __r('routes:prihlasenie', '/prihlasenie'),
      });
      break;
    }
    case BreadCrumbType.REGISTRATION: {
      path.push({
        name: __('Registrácia'),
        url: __r('routes:registracia', '/registracia'),
      });
      break;
    }

    case BreadCrumbType.FORGOT_PASSWORD: {
      path.push({
        name: __('Zabudnuté heslo'),
        url: __r('routes:zabudnute-heslo', '/zabudnute-heslo'),
      });
      break;
    }

    case BreadCrumbType.MY_ACCOUNT_ORDERS: {
      path.push({
        name: __('Môj účet'),
        url: __r('routes:moj-ucet', '/moj-ucet'),
      });
      path.push({
        name: __('Moje objednávky'),
        url: __r('routes:moj-ucet/objednavky', '/moj-ucet/objednavky'),
      });
      break;
    }
    case BreadCrumbType.MY_ACCOUNT_ORDER_DETAIL: {
      path.push({
        name: __('Môj účet'),
        url: __r('routes:moj-ucet', '/moj-ucet'),
      });
      path.push({
        name: __('Moje objednávky'),
        url: __r('routes:moj-ucet/objednavky', '/moj-ucet/objednavky'),
      });
      path.push({
        name: __('Detail objednávky'),
        url: `${__r(
          'routes:moj-ucet/objednavka',
          '/moj-ucet/objednavka',
        )}/:orderPublicId`,
      });
      break;
    }
    case BreadCrumbType.MY_ACCOUNT_DASHBOARD: {
      path.push({
        name: __('Môj účet'),
        url: __r('routes:moj-ucet', '/moj-ucet'),
      });
      path.push({
        name: __('Dashboard'),
        url: __r('routes:moj-ucet/dashboard', '/moj-ucet/dashboard'),
      });
      break;
    }
    case BreadCrumbType.MY_ACCOUNT_INVOICES: {
      path.push({
        name: __('Môj účet'),
        url: __r('routes:moj-ucet', '/moj-ucet'),
      });
      path.push({
        name: __('Faktúry a dobropisy'),
        url: __r('routes:moj-ucet/faktury', '/moj-ucet/faktury'),
      });
      break;
    }
    case BreadCrumbType.MY_ACCOUNT_PAYABLES: {
      path.push({
        name: __('Môj účet'),
        url: __r('routes:moj-ucet', '/moj-ucet'),
      });
      path.push({
        name: __('Záväzky'),
        url: __r('routes:moj-ucet/zavazky', '/moj-ucet/zavazky'),
      });
      break;
    }
    case BreadCrumbType.MY_ACCOUNT_PURCHASE: {
      path.push({
        name: __('Môj účet'),
        url: __r('routes:moj-ucet', '/moj-ucet'),
      });
      path.push({
        name: __('Moje nákupy'),
        url: __r('routes:moj-ucet/moje-nakupy', '/moj-ucet/moje-nakupy'),
      });
      break;
    }
    case BreadCrumbType.MY_ACCOUNT_BACKORDER: {
      path.push({
        name: __('Môj účet'),
        url: __r('routes:moj-ucet', '/moj-ucet'),
      });
      path.push({
        name: __('Backorder'),
        url: __r('routes:moj-ucet/backorder', '/moj-ucet/backorder'),
      });
      break;
    }
    case BreadCrumbType.MY_ACCOUNT_FAVORITES: {
      path.push({
        name: __('Môj účet'),
        url: __r('routes:moj-ucet', '/moj-ucet'),
      });
      path.push({
        name: __('Obľúbené'),
        url: __r('routes:moj-ucet/oblubene', '/moj-ucet/oblubene'),
      });
      break;
    }
    case BreadCrumbType.MY_ACCOUNT_WATCHING: {
      path.push({
        name: __('Môj účet'),
        url: __r('routes:moj-ucet', '/moj-ucet'),
      });
      path.push({
        name: __('Strážny pes'),
        url: __r('routes:moj-ucet/strazny-pes', '/moj-ucet/strazny-pes'),
      });
      break;
    }
    // case BreadCrumbType.MY_ACCOUNT_REQUESTS:
    // case BreadCrumbType.MY_ACCOUNT_REQUESTS_DETAIL: {
    //   path.push({
    //     name: __('Môj účet'),
    //     url: __r('routes:moj-ucet', '/moj-ucet'),
    //   });
    //   path.push({
    //     name: __('Moje dopyty'),
    //     url: __r('routes:moj-ucet/dopyty', '/moj-ucet/dopyty'),
    //   });
    //   break;
    // }
    case BreadCrumbType.MY_ACCOUNT_INFO: {
      path.push({
        name: __('Môj účet'),
        url: __r('routes:moj-ucet', '/moj-ucet'),
      });
      path.push({
        name: __('Moje údaje'),
        url: __r('routes:moj-ucet/moje-udaje', '/moj-ucet/moje-udaje'),
      });
      break;
    }
    case BreadCrumbType.MY_ACCOUNT_DELIVERY_ADDRESSES: {
      path.push({
        name: __('Môj účet'),
        url: __r('routes:moj-ucet', '/moj-ucet'),
      });
      path.push({
        name: __('Moje dodacie adresy'),
        url: __r(
          'routes:moj-ucet/moje-dodacie-adresy',
          '/moj-ucet/moje-dodacie-adresy',
        ),
      });
      break;
    }
    case BreadCrumbType.MY_ACCOUNT_ACCOUNTS: {
      path.push({
        name: __('Môj účet'),
        url: __r('routes:moj-ucet', '/moj-ucet'),
      });
      path.push({
        name: __('Správa účtov'),
        url: __r('routes:moj-ucet/sprava-uctov', '/moj-ucet/sprava-uctov'),
      });
      break;
    }
    case BreadCrumbType.MY_ACCOUNT_CHANGE_PASSWORD: {
      path.push({
        name: __('Môj účet'),
        url: __r('routes:moj-ucet', '/moj-ucet'),
      });
      path.push({
        name: __('Zmena hesla'),
        url: __r('routes:moj-ucet/zmena-hesla', '/moj-ucet/zmena-hesla'),
      });
      break;
    }

    case BreadCrumbType.MY_ACCOUNT_SETTINGS: {
      path.push({
        name: __('Môj účet'),
        url: __r('routes:moj-ucet', '/moj-ucet'),
      });
      path.push({
        name: __('Nastavenia'),
        url: __r('routes:moj-ucet/nastavenia', '/moj-ucet/nastavenia'),
      });
      break;
    }

    case BreadCrumbType.CONTACT: {
      path.push({
        name: __('Kontakt'),
        url: __r('routes:kontakt', '/kontakt'),
      });
      break;
    }

    case BreadCrumbType.CMS_ARTICLES: {
      path.push({
        name: __(data.name),
        url: __r(`routes:${data.path}`, `${data.path}`),
      });
      break;
    }

    case BreadCrumbType.CMS: {
      path.push({
        name: __('Blog'),
        url: __r('routes:blog', '/blog'),
      });
      path.push(createBreadCrumbFromCMSArticle(data));
      break;
    }

    case BreadCrumbType.CART: {
      path.push({
        name: __('Nákupný košík'),
        url: prop(data, 'url', __r(cartRoutes.INDEX, '/kosik')),
      });
      break;
    }

    case BreadCrumbType.ACTIONS: {
      path.push({
        name: __('Akcie'),
        url: __r('routes:akcie', '/akcie'),
      });
      break;
    }

    case BreadCrumbType.ACTION: {
      path.push({
        name: __('Akcie'),
        url: __r('routes:akcie', '/akcie'),
      });

      path.push({
        name: data.name ? data.name : '',
        url: `${data.url}`,
      });
      break;
    }

    case BreadCrumbType.SELLERS: {
      path.push({
        name: __('Predajcovia'),
        url: __r('routes:predajcovia', '/predajcovia'),
      });
      break;
    }

    case BreadCrumbType.FAQ: {
      path.push({
        name: __('Podpora'),
        url: __r('routes:podpora', '/podpora'),
      });
      path.push({
        name: __('FAQ'),
        url: __r('routes:podpora/faq', '/podpora/faq'),
      });
      break;
    }

    case BreadCrumbType.SUPPORT: {
      path.push({
        name: __('Podpora'),
        url: __r('routes:podpora', '/podpora'),
      });
      break;
    }

    case BreadCrumbType.CUSTOM: {
      path.push({
        name: data.name,
        url: data.url,
      });
      break;
    }

    case BreadCrumbType.SEARCH: {
      path.push({
        name: __('Vyhľadávanie'),
        url: '/',
        justText: true,
      });

      path.push({
        name: data.name ? data.name.toUpperCase() : '',
        url: `${__r('routes:vyhladavanie', '/vyhladavanie')}?q=${data.name}`,
      });

      break;
    }

    case BreadCrumbType.BRAND: {
      path.push({
        name: data.name,
        url: '/',
        justText: true,
      });

      break;
    }

    case BreadCrumbType.NONE: {
      break;
    }

    case BreadCrumbType.MY_ACCOUNT_SERIAL_NUMBERS: {
      path.push({
        name: __('Môj účet'),
        url: __r('routes:moj-ucet', '/moj-ucet'),
      });
      path.push({
        name: __('Moje sériové čísla'),
        url: __r(
          'routes:moj-ucet/moje-seriove-cisla',
          '/moj-ucet/moje-seriove-cisla',
        ),
      });
      break;
    }

    default:
      break;
  }
  dispatch(setBreadCrumb({ path }));
};

const createBreadCrumbFromCategory = (
  category: any,
  byId,
): SingleBreadCrumb => {
  const name =
    prop(category, 'publish.0.content.json_content.shortname') ||
    prop(category, 'publish.0.category_name') ||
    prop(category, 'category_name', '');
  const id = prop(category, 'category_id', '');
  const categoryUrl =
    (byId
      ? prop(byId, `${category.redirect_category_id}.publish.0.url`)
      : false) ||
    prop(category, 'publish.0.url') ||
    prop(category, 'url', 'url');
  const url = resolveCategoryUrl(id, categoryUrl || 'url', 'CATEGORYTEXT/URL');

  return { name, url };
};

const createBreadCrumbFromProduct = (product: any): SingleBreadCrumb => {
  const name = prop(
    product,
    'publish.0.product_name',
    prop(product, 'name', ''),
  );
  const id = prop(product, 'product_id', '');
  const productUrl = prop(product, 'url', 'url');
  const url = resolveProductUrl(id, productUrl);

  return { name, url };
};

const createBreadCrumbFromCMSArticle = (article: any): SingleBreadCrumb => {
  const name = prop(article, 'name', '');
  const url = `/${prop(article, 'url', 'url')}`;

  return { name, url };
};
