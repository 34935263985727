import API from './services/API';
import * as cookies from 'react-cookies';
import { LANG_COOKIE } from './containers/App/constants';
import { prop } from './utilities';

const enableDevelopmentMode = false;

export async function loadTranslations(location) {
  return API.loadLangTranslation(
    resolveLangByHostname(prop(location, 'hostname')),
  );
}

export function resolveLangFromCookies(hostname: string): string {
  return cookies.load(LANG_COOKIE) || 'sk';
}

export function resolveLangByHostname(hostname: string): string {
  // switch (hostname) {
  //   case 'beta.jbl.sk':
  //     return 'sk';
  //   case 'beta.jbl.cz':
  //     return 'cz';
  //   default:
  //     return 'sk';
  // }
  if (process.env.NODE_ENV === 'development' && enableDevelopmentMode) {
    return 'cz';
  }
  if (
    hostname &&
    hostname.includes('cz')
    // process &&
    // process.env &&
    // process.env.REACT_APP_LANG_OTHER_VERSION &&
    // process.env.REACT_APP_LANG_OTHER_VERSION.includes(hostname)
  ) {
    return 'cz';
  } else {
    return 'sk';
  }
}

export function resolveCurrencyByHostname(hostname: string): string {
  if (process.env.NODE_ENV === 'development' && enableDevelopmentMode) {
    return 'CZK';
  }

  if (
    hostname &&
    hostname.includes('cz')
    // process &&
    // process.env &&
    // process.env.REACT_APP_LANG_OTHER_VERSION &&
    // process.env.REACT_APP_LANG_OTHER_VERSION.includes(hostname)
  ) {
    return 'CZK';
  } else {
    return 'EUR';
  }
}

export const DEFAULT_SITEMAP_ID = {
  'beta.jbl.sk': { sk: 299, cz: 328 },
  'beta.jbl.cz': { sk: 299, cz: 328 },
  'jbl.sk': { sk: 299, cz: 328 },
  'www.jbl.sk': { sk: 299, cz: 328 },
  'jbl.cz': { sk: 299, cz: 328 },
  'www.jbl.cz': { sk: 299, cz: 328 },
  'beta.ariete.sk': { sk: 397, cz: 423 },
  'ariete.sk': { sk: 397, cz: 423 },
  'www.ariete.sk': { sk: 397, cz: 423 },
  'beta.ariete.cz': { sk: 397, cz: 423 },
  'ariete.cz': { sk: 397, cz: 423 },
  'www.ariete.cz': { sk: 397, cz: 423 },
  'beta.laica.sk': { sk: 569, cz: 593 },
  'laica.sk': { sk: 569, cz: 593 },
  'www.laica.sk': { sk: 569, cz: 593 },
  'beta.laicaitaly.cz': { sk: 569, cz: 593 },
  'laicaitaly.cz': { sk: 569, cz: 593 },
  'www.laicaitaly.cz': { sk: 569, cz: 593 },
  localhost: { sk: 299, cz: 328 },
  'localhost:7001': { sk: 299, cz: 328 },
  'kenwood-electronics.sk': { sk: 369, cz: 750 },
  'beta.kenwood-electronics.sk': { sk: 369, cz: 750 },
  'www.kenwood-electronics.sk': { sk: 369, cz: 750 },
  'kenwood.cz': { sk: 369, cz: 750 },
  'beta.kenwood.cz': { sk: 369, cz: 750 },
  'www.kenwood.cz': { sk: 369, cz: 750 },

  'status-pomocnici.sk': { sk: 793, cz: 795 },
  'beta.status-pomocnici.sk': { sk: 793, cz: 795 },
  'www.status-pomocnici.sk': { sk: 793, cz: 795 },
  'status-pomocnici.cz': { sk: 793, cz: 795 },
  'beta.status-pomocnici.cz': { sk: 793, cz: 795 },
  'www.status-pomocnici.cz': { sk: 793, cz: 795 },

  'harmankardon.sk': { sk: 851, cz: 852 },
  'beta.harmankardon.sk': { sk: 851, cz: 852 },
  'www.harmankardon.sk': { sk: 851, cz: 852 },
  'harmanshop.cz': { sk: 851, cz: 852 },
  'beta.harmanshop.cz': { sk: 851, cz: 852 },
  'www.harmanshop.cz': { sk: 851, cz: 852 },

  'volumizer.sk': { sk: 913, cz: 943 },
  'beta.volumizer.sk': { sk: 913, cz: 943 },
  'www.volumizer.sk': { sk: 913, cz: 943 },
  'volumizer.cz': { sk: 913, cz: 943 },
  'beta.volumizer.cz': { sk: 913, cz: 943 },
  'www.volumizer.cz': { sk: 913, cz: 943 },
};

export const DEFAULT_CATEGORY_ID = {
  'beta.jbl.sk': { sk: 1091, cz: 1091 },
  'beta.jbl.cz': { sk: 1091, cz: 1091 },
  'www.jbl.sk': { sk: 1091, cz: 1091 },
  'jbl.sk': { sk: 1091, cz: 1091 },
  'jbl.cz': { sk: 1091, cz: 1091 },
  'www.jbl.cz': { sk: 1091, cz: 1091 },
  'beta.ariete.sk': { sk: 66973, cz: 66973 },
  'beta.ariete.cz': { sk: 66973, cz: 66973 },
  'ariete.sk': { sk: 66973, cz: 66973 },
  'www.ariete.sk': { sk: 66973, cz: 66973 },
  'ariete.cz': { sk: 66973, cz: 66973 },
  'www.ariete.cz': { sk: 66973, cz: 66973 },
  'beta.laica.sk': { sk: 135528, cz: 135528 },
  'beta.laicaitaly.cz': { sk: 135528, cz: 135528 },
  'laica.sk': { sk: 135528, cz: 135528 },
  'www.laica.sk': { sk: 135528, cz: 135528 },
  'laicaitaly.cz': { sk: 135528, cz: 135528 },
  'www.laicaitaly.cz': { sk: 135528, cz: 135528 },

  localhost: { sk: 1091, cz: 1091 },
  'localhost:7001': { sk: 1091, cz: 1091 },
  'localhost:3000': { sk: 1091, cz: 1091 },

  'kenwood-electronics.sk': { sk: 3676, cz: 3676 },
  'beta.kenwood-electronics.sk': { sk: 3676, cz: 3676 },
  'www.kenwood-electronics.sk': { sk: 3676, cz: 3676 },
  'kenwood.cz': { sk: 3676, cz: 3676 },
  'beta.kenwood.cz': { sk: 3676, cz: 3676 },
  'www.kenwood.cz': { sk: 3676, cz: 3676 },

  'status-pomocnici.sk': { sk: 135641, cz: 135641 },
  'beta.status-pomocnici.sk': { sk: 135641, cz: 135641 },
  'www.status-pomocnici.sk': { sk: 135641, cz: 135641 },
  'status-pomocnici.cz': { sk: 135641, cz: 135641 },
  'beta.status-pomocnici.cz': { sk: 135641, cz: 135641 },
  'www.status-pomocnici.cz': { sk: 135641, cz: 135641 },

  'harmankardon.sk': { sk: 135659, cz: 135659 },
  'beta.harmankardon.sk': { sk: 135659, cz: 135659 },
  'www.harmankardon.sk': { sk: 135659, cz: 135659 },
  'harmanshop.cz': { sk: 135659, cz: 135659 },
  'beta.harmanshop.cz': { sk: 135659, cz: 135659 },
  'www.harmanshop.cz': { sk: 135659, cz: 135659 },

  'volumizer.sk': { sk: 135709, cz: 135709 },
  'beta.volumizer.sk': { sk: 135709, cz: 135709 },
  'www.volumizer.sk': { sk: 135709, cz: 135709 },
  'volumizer.cz': { sk: 135709, cz: 135709 },
  'beta.volumizer.cz': { sk: 135709, cz: 135709 },
  'www.volumizer.cz': { sk: 135709, cz: 135709 },
};

export function resolveBaseUrlByLang(hostname?: string, lang?: string): string {
  if (!hostname && window) {
    hostname = window.location.hostname;
  }

  // default is base url
  if (!lang) {
    return process.env.REACT_APP_BASE_URL as string;
  }

  if (hostname) {
    if (hostname.includes('ariete')) {
      // we are returning other url - if lang is cz we return sk url
      if (lang === 'cz') {
        return 'https://www.ariete.cz/';
      } else {
        return 'https://www.ariete.sk/';
      }
    } else if (hostname.includes('laica')) {
      // we are returning other url - if lang is cz we return sk url
      if (lang === 'cz') {
        return 'https://www.laicaitaly.cz/';
      } else {
        return 'https://www.laica.sk/';
      }
    } else if (hostname.includes('kenwood')) {
      if (lang === 'cz') {
        return 'https://www.kenwood.cz/';
      } else {
        return 'https://www.kenwood-electronics.sk/';
      }
    } else if (hostname.includes('status')) {
      if (lang === 'cz') {
        return 'https://www.status-pomocnici.cz/';
      } else {
        return 'https://www.status-pomocnici.sk/';
      }
    } else if (hostname.includes('harman')) {
      if (lang === 'cz') {
        return 'https://www.harmanshop.cz/';
      } else {
        return 'https://www.harmankardon.sk/';
      }
    } else if (hostname.includes('volumizer')) {
      if (lang === 'cz') {
        return 'https://www.volumizer.cz/';
      } else {
        return 'https://www.volumizer.sk/';
      }
    } else {
      if (lang === 'cz') {
        return 'https://www.jbl.cz/';
      } else {
        return 'https://www.jbl.sk/';
      }
    }
  }

  return 'https://www.jbl.sk/';
}

export function resolveOtherBaseUrlByLang(lang?: string): string {
  // default is base url
  if (!lang) {
    return process.env.REACT_APP_BASE_URL as string;
  }
  // we are returning other url - if lang is cz we return sk url
  if (lang === 'cz') {
    return process.env.REACT_APP_BASE_URL as string;
  } else {
    return process.env.REACT_APP_LANG_OTHER_VERSION as string;
  }
}

export function getOtherLang(lang: string): string {
  if (lang === 'cz') {
    return 'sk';
  } else {
    return 'cz';
  }
}

export function resolveThemeByHostname(hostname: string, theme: any): any {
  if (!hostname && window) {
    hostname = window.location.hostname;
  }
  if (process.env.NODE_ENV === 'development' && enableDevelopmentMode) {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary: '#018ac1',
        loaderPrimary: '#018ac1',
      },
      categoryColors: {
        ...theme.categoryColors,
        handle: {
          ...theme.categoryColors.handle,
          border: '2px solid #018ac1',
        },
        track: {
          ...theme.categoryColors.track,
          background: '#018ac1',
        },
      },
      categoryFilter: {
        ...theme.categoryFilter,
        sliderTrackColor: '#018ac1',
        trackBorderColor: '#018ac1',
      },
      cart: {
        ...theme.cart,
        totalPrice: {
          ...theme.cart.totalPrice,
          color: '#018ac1',
        },
        discountCard: {
          ...theme.cart.discountCard,
          useButtonColor: '#018ac1',
        },
        cartSummary: {
          ...theme.cart.cartSummary,
          primary: '#018ac1',
        },
      },
      mobileBanner: {
        backgroundColor: '#000000',
        textColor: '#ffffff',
        buttonBackgroundColor: '#ff3300',
        buttonTextColor: '#ffffff',
        dotsBackgroundColor: '#000000',
        dotsColor: '#ff3300',
        dotsInactiveColor: '#ffffff3d',
      },
      buttonPrimary: '#018ac1',
    };
  }

  if (hostname && hostname.includes('ariete')) {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary: '#cc2229',
        loaderPrimary: '#cc2229',
      },
      categoryColors: {
        ...theme.categoryColors,
        handle: {
          ...theme.categoryColors.handle,
          border: '2px solid #cc2229',
        },
        track: {
          ...theme.categoryColors.track,
          background: '#cc2229',
        },
      },
      categoryFilter: {
        ...theme.categoryFilter,
        sliderTrackColor: '#cc2229',
        trackBorderColor: '#cc2229',
      },
      cart: {
        ...theme.cart,
        totalPrice: {
          ...theme.cart.totalPrice,
          color: '#cc2229',
        },
        discountCard: {
          ...theme.cart.discountCard,
          useButtonColor: '#cc2229',
        },
        cartSummary: {
          ...theme.cart.cartSummary,
          primary: '#cc2229',
        },
      },
      mobileBanner: {
        backgroundColor: '#ffffff',
        textColor: '#000000',
        buttonBackgroundColor: '#e40232',
        buttonTextColor: '#ffffff',
        dotsBackgroundColor: '#ffffff',
        dotsColor: '#e40232',
        dotsInactiveColor: '#000000',
      },
      buttonPrimary: '#cc2229',
    };
  } else if (hostname && hostname.includes('laica')) {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary: '#018ac1',
        loaderPrimary: '#018ac1',
      },
      categoryColors: {
        ...theme.categoryColors,
        handle: {
          ...theme.categoryColors.handle,
          border: '2px solid #018ac1',
        },
        track: {
          ...theme.categoryColors.track,
          background: '#018ac1',
        },
      },
      categoryFilter: {
        ...theme.categoryFilter,
        sliderTrackColor: '#018ac1',
        trackBorderColor: '#018ac1',
      },
      cart: {
        ...theme.cart,
        totalPrice: {
          ...theme.cart.totalPrice,
          color: '#018ac1',
        },
        discountCard: {
          ...theme.cart.discountCard,
          useButtonColor: '#018ac1',
        },
        cartSummary: {
          ...theme.cart.cartSummary,
          primary: '#018ac1',
        },
      },
      mobileBanner: {
        backgroundColor: '#ffffff',
        textColor: '#000000',
        buttonBackgroundColor: '#1e3986',
        buttonTextColor: '#ffffff',
        dotsBackgroundColor: '#ffffff',
        dotsColor: '#1e3986',
        dotsInactiveColor: '#000000',
      },
      buttonPrimary: '#018ac1',
    };
  } else if (hostname && hostname.includes('kenwood')) {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary: '#ff0000',
        loaderPrimary: '#ff0000',
      },
      categoryColors: {
        ...theme.categoryColors,
        handle: {
          ...theme.categoryColors.handle,
          border: '2px solid #ff0000',
        },
        track: {
          ...theme.categoryColors.track,
          background: '#ff0000',
        },
      },
      categoryFilter: {
        ...theme.categoryFilter,
        sliderTrackColor: '#ff0000',
        trackBorderColor: '#ff0000',
      },
      cart: {
        ...theme.cart,
        totalPrice: {
          ...theme.cart.totalPrice,
          color: '#ff0000',
        },
        discountCard: {
          ...theme.cart.discountCard,
          useButtonColor: '#ff0000',
        },
        cartSummary: {
          ...theme.cart.cartSummary,
          primary: '#ff0000',
        },
      },
      mobileBanner: {
        backgroundColor: '#000000',
        textColor: '#ffffff',
        buttonBackgroundColor: '#ff0000',
        buttonTextColor: '#ffffff',
        dotsBackgroundColor: '#000000',
        dotsColor: '#ff0000',
        dotsInactiveColor: '#ffffff3d',
      },
      buttonPrimary: '#ff0000',
    };
  } else if (hostname && hostname.includes('status')) {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary: '#ed1720',
        loaderPrimary: '#ed1720',
      },
      categoryColors: {
        ...theme.categoryColors,
        handle: {
          ...theme.categoryColors.handle,
          border: '2px solid #ff0000',
        },
        track: {
          ...theme.categoryColors.track,
          background: '#ed1720',
        },
      },
      categoryFilter: {
        ...theme.categoryFilter,
        sliderTrackColor: '#ed1720',
        trackBorderColor: '#ed1720',
      },
      cart: {
        ...theme.cart,
        totalPrice: {
          ...theme.cart.totalPrice,
          color: '#ed1720',
        },
        discountCard: {
          ...theme.cart.discountCard,
          useButtonColor: '#ed1720',
        },
        cartSummary: {
          ...theme.cart.cartSummary,
          primary: '#ed1720',
        },
      },
      mobileBanner: {
        backgroundColor: '#d82328',
        textColor: '#ffffff',
        buttonBackgroundColor: '#ffffff',
        buttonTextColor: '#d82328',
        dotsBackgroundColor: '#d82328',
        dotsColor: '#ffffff',
        dotsInactiveColor: '#ffffff3d',
      },
      buttonPrimary: '#ed1720',
    };
  } else if (hostname && hostname.includes('volumizer')) {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary: '#e70b2e',
        loaderPrimary: '#e70b2e',
      },
      categoryColors: {
        ...theme.categoryColors,
        handle: {
          ...theme.categoryColors.handle,
          border: '2px solid #ff0000',
        },
        track: {
          ...theme.categoryColors.track,
          background: '#e70b2e',
        },
      },
      categoryFilter: {
        ...theme.categoryFilter,
        sliderTrackColor: '#e70b2e',
        trackBorderColor: '#e70b2e',
      },
      cart: {
        ...theme.cart,
        totalPrice: {
          ...theme.cart.totalPrice,
          color: '#e70b2e',
        },
        discountCard: {
          ...theme.cart.discountCard,
          useButtonColor: '#e70b2e',
        },
        cartSummary: {
          ...theme.cart.cartSummary,
          primary: '#e70b2e',
        },
      },
      mobileBanner: {
        backgroundColor: '#f7e9e3',
        textColor: '#000000',
        buttonBackgroundColor: '#d22d3a',
        buttonTextColor: '#f7e9e3',
        dotsBackgroundColor: '#f7e9e3',
        dotsColor: '#d22d3a',
        dotsInactiveColor: '#000000',
      },
      buttonPrimary: '#e70b2e',
    };
  } else if (hostname && hostname.includes('harman')) {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary: '#000000',
        loaderPrimary: '#000000',
      },
      categoryColors: {
        ...theme.categoryColors,
        handle: {
          ...theme.categoryColors.handle,
          border: '2px solid #ff0000',
        },
        track: {
          ...theme.categoryColors.track,
          background: '#000000',
        },
      },
      categoryFilter: {
        ...theme.categoryFilter,
        sliderTrackColor: '#000000',
        trackBorderColor: '#000000',
      },
      cart: {
        ...theme.cart,
        totalPrice: {
          ...theme.cart.totalPrice,
          color: '#000000',
        },
        discountCard: {
          ...theme.cart.discountCard,
          useButtonColor: '#000000',
        },
        cartSummary: {
          ...theme.cart.cartSummary,
          primary: '#000000',
        },
      },
      mobileBanner: {
        backgroundColor: '#000000',
        textColor: '#ffffff',
        buttonBackgroundColor: '#ffffff',
        buttonTextColor: '#000000',
        dotsBackgroundColor: '#000000',
        dotsColor: '#ffffff',
        dotsInactiveColor: '#ffffff3d',
      },
      buttonPrimary: '#000000',
    };
  } else {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary: '#FF3300',
        loaderPrimary: '#FF3300',
      },
      categoryColors: {
        ...theme.categoryColors,
        handle: {
          ...theme.categoryColors.handle,
          border: '2px solid #FF3300',
        },
        track: {
          ...theme.categoryColors.track,
          background: '#FF3300',
        },
      },
      categoryFilter: {
        ...theme.categoryFilter,
        sliderTrackColor: '#FF3300',
        trackBorderColor: '#FF3300',
      },
      cart: {
        ...theme.cart,
        totalPrice: {
          ...theme.cart.totalPrice,
          color: '#FF3300',
        },
        discountCard: {
          ...theme.cart.discountCard,
          useButtonColor: '#FF3300',
        },
        cartSummary: {
          ...theme.cart.cartSummary,
          primary: '#FF3300',
        },
      },
      mobileBanner: {
        backgroundColor: '#000000',
        textColor: '#ffffff',
        buttonBackgroundColor: '#ff3300',
        buttonTextColor: '#ffffff',
        dotsBackgroundColor: '#000000',
        dotsColor: '#ff3300',
        dotsInactiveColor: '#ffffff3d',
      },
      buttonPrimary: '#FF3300',
    };
  }
}

export function resolveLogoByHostname(hostname: string): string {
  if (process.env.NODE_ENV === 'development' && enableDevelopmentMode) {
    return '/images/status/status-logo.png';
  }

  if (hostname && hostname.includes('ariete')) {
    return '/images/corporate/ariete.svg';
  } else if (hostname && hostname.includes('laica')) {
    return '/images/corporate/laica_logo.png';
  } else if (hostname && hostname.includes('kenwood')) {
    return '/images/kenwood/kenwood-logo.png';
  } else if (hostname && hostname.includes('status')) {
    return '/images/status/status-logo.png';
  } else if (hostname && hostname.includes('harman')) {
    return '/images/harman/harman-logo.png';
  } else if (hostname && hostname.includes('volumizer')) {
    return '/images/volumizer/volumizer-logo.jpeg';
  } else {
    return '/images/corporate/jbl.svg';
  }
}

export function resolveBrandByHostname(hostname: string): string {
  if (process.env.NODE_ENV === 'development' && enableDevelopmentMode) {
    return 'JBL';
  }

  if (hostname && hostname.includes('ariete')) {
    return 'ARIETE';
  } else if (hostname && hostname.includes('laica')) {
    return 'LAICA';
  } else if (hostname && hostname.includes('kenwood')) {
    return 'KENWOOD';
  } else if (hostname && hostname.includes('status')) {
    return 'STATUS';
  } else if (hostname && hostname.includes('harman')) {
    return 'HARMAN/KARDON ';
  } else if (hostname && hostname.includes('volumizer')) {
    return 'VOLUMIZER ';
  } else {
    return 'JBL';
  }
}

export function resolveTagIdByHostname(hostname: string): number {
  if (process.env.NODE_ENV === 'development' && enableDevelopmentMode) {
    return 1020;
  }

  if (hostname && hostname.includes('ariete')) {
    return 1143;
  } else if (hostname && hostname.includes('laica')) {
    return 1151;
  } else if (hostname && hostname.includes('kenwood')) {
    return 1027;
  } else if (hostname && hostname.includes('status')) {
    return -1;
  } else if (hostname && hostname.includes('harman')) {
    return -1;
  } else if (hostname && hostname.includes('volumizer')) {
    return -1;
  } else {
    return 1020;
  }
}

export function resolveTagByHostname(hostname: string): string {
  if (process.env.NODE_ENV === 'development' && enableDevelopmentMode) {
    return 'JBL';
  }

  if (hostname && hostname.includes('ariete')) {
    return 'ARIETE';
  } else if (hostname && hostname.includes('laica')) {
    return 'LAICA';
  } else if (hostname && hostname.includes('kenwood')) {
    return 'KENWOOD';
  } else if (hostname && hostname.includes('status')) {
    return 'STATUS';
  } else if (hostname && hostname.includes('harman')) {
    return 'HARMAN';
  } else if (hostname && hostname.includes('volumizer')) {
    return 'VOLUMIZER';
  } else {
    return 'JBL';
  }
}

export function resolveDomainByHostname(hostname: string): string {
  if (process.env.NODE_ENV === 'development' && enableDevelopmentMode) {
    return '1';
  }

  if (hostname && hostname.includes('kenwood')) {
    return '2';
  } else if (hostname && hostname.includes('ariete')) {
    return '3';
  } else if (hostname && hostname.includes('laica')) {
    return '5';
  } else if (hostname && hostname.includes('status')) {
    return '6';
  } else if (hostname && hostname.includes('harman')) {
    return '7';
  } else if (hostname && hostname.includes('volumizer')) {
    return '8';
  } else {
    return '1';
  }
}

export const DEFAULT_RADCA_ID = {
  'beta.jbl.sk': { sk: 316, cz: 347 },
  'beta.jbl.cz': { sk: 316, cz: 347 },
  'www.jbl.sk': { sk: 316, cz: 347 },
  'jbl.sk': { sk: 316, cz: 347 },
  'jbl.cz': { sk: 316, cz: 347 },
  'www.jbl.cz': { sk: 316, cz: 347 },
  'beta.ariete.sk': { sk: 447, cz: 455 },
  'beta.ariete.cz': { sk: 447, cz: 455 },
  'ariete.sk': { sk: 447, cz: 455 },
  'www.ariete.sk': { sk: 447, cz: 455 },
  'ariete.cz': { sk: 447, cz: 455 },
  'www.ariete.cz': { sk: 447, cz: 455 },
  'beta.laica.sk': { sk: 619, cz: 631 },
  'beta.laicaitaly.cz': { sk: 619, cz: 631 },
  'laica.sk': { sk: 619, cz: 631 },
  'www.laica.sk': { sk: 619, cz: 631 },
  'laicaitaly.cz': { sk: 619, cz: 631 },
  'www.laicaitaly.cz': { sk: 619, cz: 631 },
  localhost: { sk: 316, cz: 347 },
  'localhost:7001': { sk: 316, cz: 347 },
};

export const DEFAULT_TEMA_ID = {
  'beta.jbl.sk': { sk: 318, cz: 347 },
  'beta.jbl.cz': { sk: 318, cz: 347 },
  'www.jbl.sk': { sk: 318, cz: 347 },
  'jbl.sk': { sk: 318, cz: 347 },
  'jbl.cz': { sk: 318, cz: 347 },
  'www.jbl.cz': { sk: 318, cz: 347 },
  'beta.ariete.sk': { sk: 449, cz: 455 },
  'beta.ariete.cz': { sk: 449, cz: 455 },
  'ariete.sk': { sk: 449, cz: 455 },
  'www.ariete.sk': { sk: 449, cz: 455 },
  'ariete.cz': { sk: 449, cz: 455 },
  'www.ariete.cz': { sk: 449, cz: 455 },
  'beta.laica.sk': { sk: 621, cz: 633 },
  'beta.laicaitaly.cz': { sk: 621, cz: 633 },
  'laica.sk': { sk: 621, cz: 633 },
  'www.laica.sk': { sk: 621, cz: 633 },
  'laicaitaly.cz': { sk: 621, cz: 633 },
  'www.laicaitaly.cz': { sk: 621, cz: 633 },
  localhost: { sk: 318, cz: 347 },
  'localhost:7001': { sk: 318, cz: 347 },
};

export function resolveAdventScriptId(domainId, lang: string): string {
  const joinedString = `${domainId}${lang}`;
  switch (joinedString) {
    case '1sk':
      return 'ss_637f77b3974f92022110194&amp;id=637f77b3974f9';
    case '1cz':
      return 'ss_637f79202ae9e2022110194&amp;id=637f79202ae9e';
    case '3sk':
      return 'ss_6374cb471ddd620221101303&amp;id=6374cb471ddd6';
    case '3cz':
      return 'ss_636ccff462cd720221101303&amp;id=636ccff462cd7';
    case '5sk':
      return 'ss_637b5375cf74e20221101904&amp;id=637b5375cf74e';
    case '5cz':
      return 'ss_637b67e5e978a20221101904&amp;id=637b67e5e978a';
    default:
      return '';
  }
}

export function extractBaseDomain(url: string): string {
  const domainRegex = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n]+)/im;
  const match = url.match(domainRegex);

  if (match && match.length >= 2) {
    const domain = match[1];
    const parts = domain.split('.').slice(-2); // Extract last two parts of the domain

    // Check if the domain is "localhost"
    if (domain === 'localhost' || domain.endsWith('.localhost')) {
      return 'localhost'; // Return "localhost" for localhost URLs
    }

    // Check if the first part is "www" or "beta"
    if (parts[0] === 'www' || parts[0] === 'beta') {
      return domain; // Return the original domain if "www" or "beta" is in the first part
    }

    return parts.join('.');
  }

  return ''; // Return null if the base domain extraction fails
}

export function extractRustPart(url: string): string | null {
  const parts = url.split('.');
  if (parts.length === 2 && parts[1] === 'localhost') {
    return parts[0];
  }

  if (parts.length === 3) {
    if (parts[0] === 'www' || parts[0] === 'beta' || parts[0] === 'local') {
      return null;
    } else {
      return parts[0];
    }
  }

  return null;
}

export function extractBaseDomainUppercase(url: string): string {
  const baseDomain = extractBaseDomain(url);
  return convertToUppercaseBeforeDot(baseDomain);
}

function convertToUppercaseBeforeDot(input: string): string {
  const parts = input.split('.');
  if (parts.length > 1) {
    parts[0] = parts[0].toUpperCase();
    return parts.join('.');
  }
  return input; // No dot found, return the input as is.
}
